import { Box, FormControl, Radio, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react'
import AssessmentSearchForm from './AssessmentOwnerSearchForm';
import ErrorMessage from '../../components/ui/errorMessage/ErrorMessage';
import SpectrTablePagination from '../../components/ui/spectr-table-pagination/SpectrTablePagination';
import { OWNER_SEARCH_TAB, POLICY_OWNER_SEARCH_TAB } from '../../utils/constants/tabConstants';
import MatchStatus from '../../components/ui/matchStatus/MatchStatus';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { AssesementSearchFormType } from '../../entities/Types/AssesementSearchFormType';
import { getSessionStorage, setSessionStorage, specialCharacterFormatting } from '../../utils/common/commonUtils';
import { AssessmentSearchRequest } from '../../entities/ApiModel/AssessmentSearchRequest';
import {
    AssessmentSearchFormErrorMessages, EXCEPTION_MATCH_TEXT, keyEntriesPerPage,
    keyPageNo, MULTIPLE_MATCH_RESULT_TEXT, MULTIPLE_MATCH_TEXT, NO_COVERAGE_TEXT, NO_MATCH_FOUND, OwnerNameSearchErrorMessages, SINGLE_MATCH_TEXT
} from '../../utils/constants/constants';
import MatchCodes from '../../utils/enums/MatchCodes';
import LoadingProgressModel from '../../components/ui/circularProgress/LoadingProgress';
import AssessmentOwnerSearchForm from './AssessmentOwnerSearchForm';
import { TabPanel } from '@mui/lab';
import styles from './assessmentOwnerSearch.module.css';
import { StyledRadioButton } from '../../components/StyledComponents/StyledRadioButton';
import { ValidationText } from '../../components/StyledComponents/CommonControls';
import { AssessmentOwnerSearchData, resetAssessmentOwnerSearchState, searchByAssessmentOwnerSearch, setAssessmentOwnerSearchToState } from './AssessmentOwnerSearchSlice';
import { OwnerNameSearchRequest } from '../../entities/ApiModel/OwnerNameSearchRequest';
import { OwnerNameSearchForm } from '../../entities/Types/OwnerNameSearchForm';
import PolicyOwnerNameSearch from '../PolicyOwnerNameSearch/PolicyOwnerNameSearch';
import { tabIdentifier } from '../../components/ui/propertyTable/tabIdentifierSlice';

export const errorMessages = {
    InvalidOwnerNameError: "OwnerName_Is_Invalid",
    MaxCharactersError: "Maximum_50_Characters",
    OwnerNameRequiredError: "OwnerName_Is_Required"
}

function OwnerNameSearch() {

    const [errorMessage, setErrorMessage] = useState("");
    const [matchCode, setMatchCode] = useState<string>("");
    const [matchText, setMatchText] = useState<string>("");
    const [resultText, setResultText] = useState<string>("");

    // const [isAssesementOwnerSearch, setAssesementOwnerSearch] = React.useState(true);
    // const [isPolicyOwnerSearch, setPolicyOwnerSearch] = React.useState(false);

    const assessmentOwnerSearchState = useAppSelector(AssessmentOwnerSearchData);
    const [isLoading, setLoading] = useState(false);
    const tabState = useAppSelector(tabIdentifier);
    const dispatch = useAppDispatch();

    const policyOwnerSearch = "policyOwnerSearch";
    const assessmentOwnerSearch = "assessmentOwnerSearch";

    const [selectedOwnerSearch, setSelectedOwnerSearch] = useState(
        tabState?.subTabName === POLICY_OWNER_SEARCH_TAB ? policyOwnerSearch : assessmentOwnerSearch
    );

    async function handleSubmit(formData: OwnerNameSearchForm) {
        if (!formData)
            return;
        dispatch(resetAssessmentOwnerSearchState());

        const defaultPageNo = 1;
        const defaultRowsPerPage = 10;
        setSessionStorage(keyPageNo + OWNER_SEARCH_TAB, defaultPageNo);
        let page = defaultPageNo;//parseInt(getSessionStorage(keyPageNo + LEGAL_SEARCH_TAB) ?? defaultPageNo.toString());
        let rowsPerPage = parseInt(getSessionStorage(keyEntriesPerPage + OWNER_SEARCH_TAB) ?? defaultRowsPerPage.toString())
        let request: OwnerNameSearchRequest = {
            state: formData.stateField.id ?? "",
            fips: formData.countyField.fips ?? "",
            county: formData.countyField.county ?? "",
            ownerName: formData.ownerName ?? "",
            offset_value: ((page - 1) * rowsPerPage).toString(),
            fetchnext_value: ((page - 1) * rowsPerPage + rowsPerPage).toString()
        }

        formData.pageNo = page;
        setMatchCode("");
        setLoading(true);

        dispatch(searchByAssessmentOwnerSearch(request));
        dispatch(setAssessmentOwnerSearchToState(JSON.parse(JSON.stringify(formData))));
    }

    // useEffect(() => {
    //     if (tabState?.subTabName) {
    //         setSelectedAPNSearch(tabState?.subTabName === POLICY_OWNER_SEARCH_TAB ? policyAPNSearch : assessmentAPNSearch);
    //     }
    // }, [tabState]);

    // useEffect(() => {
    //     if (tabState && tabState.subTabName === POLICY_OWNER_SEARCH_TAB) {
    //         setTimeout(function () {
    //             setAssesementOwnerSearch(false);
    //             setPolicyOwnerSearch(true);
    //         }, 0);
    //     }
    //     else if (tabState.tabName === OWNER_SEARCH_TAB) {
    //         setTimeout(function () {
    //             setAssesementOwnerSearch(true);
    //             setPolicyOwnerSearch(false);
    //         }, 0);
    //     }
    // }, []);

    const handleChangeRowsPerPage = (count: number) => {
        dispatch(resetAssessmentOwnerSearchState());
    }

    const handleChangePage = (newPage: number) => {
        // setPageNo(newPage);
        // dispatch(resetAssessmentOwnerSearchState());
    }

    useEffect(() => {
        if (assessmentOwnerSearchState
            && assessmentOwnerSearchState.property
            && assessmentOwnerSearchState.property.match_code !== "" && !assessmentOwnerSearchState.property.isExecuting) {
            setLoading(false)
            setMatchCode(assessmentOwnerSearchState.property.match_code);
        }

        if (assessmentOwnerSearchState.status === "failed") {
            setLoading(false);
        }
    }, [assessmentOwnerSearchState]);

    useEffect(() => {
        if (matchCode && matchCode !== "") {
            switch (matchCode) {
                case MatchCodes.NO_MATCH.toString(): {
                    setMatchText(NO_MATCH_FOUND);
                    break;
                }
                case MatchCodes.UNIQUE_MATCH.toString(): {
                    setMatchText(SINGLE_MATCH_TEXT);
                    break;
                }
                case MatchCodes.MULTIPLE_MATCH.toString(): {
                    setMatchText(MULTIPLE_MATCH_TEXT);
                    setResultText(MULTIPLE_MATCH_RESULT_TEXT);
                    break;
                }
                case MatchCodes.NO_COVERAGE.toString(): {
                    setMatchText(NO_COVERAGE_TEXT);
                    break;
                }
                case MatchCodes.EXCEPTION.toString(): {
                    setMatchText(EXCEPTION_MATCH_TEXT);
                    break;
                }
                default: {
                    setMatchText("");
                    setResultText("");
                }
            }
        } else {
            setMatchText("");
            setResultText("");
        }
    }, [matchCode]);

    async function handleFetchNext(page: number, pageNumber: string, noOfRows: string) {
        setLoading(true);
        let request: OwnerNameSearchRequest = {
            state: assessmentOwnerSearchState.formFields?.stateField.id ?? "",
            fips: assessmentOwnerSearchState?.formFields?.countyField.fips ?? "",
            county: assessmentOwnerSearchState?.formFields?.countyField.county ?? "",
            ownerName: assessmentOwnerSearchState?.formFields?.ownerName ?? "",
            offset_value: pageNumber.toString(),
            fetchnext_value: noOfRows.toString(),
        }
        setMatchCode("");
        setMatchText("");
        let formData = { ...assessmentOwnerSearchState?.formFields, "pageNo": page }
        dispatch(searchByAssessmentOwnerSearch(request));
        dispatch(setAssessmentOwnerSearchToState(formData));
    }

    const handleOwnerSearchChange = (event: any) => {
        console.log(event);
        console.log(event.target.value);
        setSelectedOwnerSearch(event.target.value);
    };

    // const handleAssesementOwnerSearchClick = () => {
    //     setPolicyOwnerSearch(false)
    //     setAssesementOwnerSearch(true)
    // }

    // const handlePolicyOwnerSearchClick = () => {
    //     setAssesementOwnerSearch(false)
    //     setPolicyOwnerSearch(true)
    // }

    const handleFormError = (errors: any) => {
        if (errors.stateField?.id?.type === "required" &&
            errors.countyField?.fips?.type === "required" && errors.ownerName?.type === "required") {
            setErrorMessage(OwnerNameSearchErrorMessages.All_Fields_Required);
        }
        else if (errors.countyField?.fips?.type === "required" && errors.ownerName?.type === "required") {
            setErrorMessage(OwnerNameSearchErrorMessages.County_OwnerName_Required);
        }
        // else if (errors.countyField?.fips?.type === "required") {
        //     setErrorMessage(OwnerNameSearchErrorMessages.County_Required);
        // }
        else if (errors.ownerName?.type === "required") {
            setErrorMessage(OwnerNameSearchErrorMessages.OwnerName_Required);
        }
        // else if (errors.ownerName?.message === errorMessages.InvalidOwnerNameError) {
        //     setErrorMessage(OwnerNameSearchErrorMessages.Invalid_OwnerName);
        // }
        // else if (errors.APNField?.message === errorMessages.DoubleAstrikError) {
        //     setErrorMessage(OwnerNameSearchErrorMessages.Double_Astrik_Error);
        // }
        else if (errors.ownerName?.type === "max") {
            setErrorMessage(OwnerNameSearchErrorMessages.Maximum_APN_Error);
        }
        else {
            setErrorMessage("");
        }
    }

    async function handleClearSearch() {
        setMatchCode("");
    }

    let columns = [
        { id: 'apn', label: 'APN', minWidth: 180 },
        { id: 'ownerName', label: 'OWNER NAME', minWidth: 290 },
        {
            id: 'address',
            label: 'ADDRESS',
            minWidth: 210,
        },
        {
            id: 'city',
            label: 'CITY',
            minWidth: 130,
        },
        {
            id: 'state',
            label: 'STATE',
            minWidth: 60,
        },
        {
            id: 'formattedZip',
            label: 'ZIP',
            minWidth: 135,
            format: (value: number) => value.toFixed(2),
        },
        {
            id: 'landUse',
            label: 'LAND USE',
            minWidth: 200,
        },
        {
            id: 'assessmentLegal',
            label: 'ASSESSMENT LEGAL',
            minWidth: 195,
        }
    ];

    return (
        <>
            {isLoading &&
                <LoadingProgressModel />
            }
            <TabPanel value="ownerSearchTab" sx={{ padding: 0 }}>
                <Box className={styles.boxContent}>
                    <FormControl>
                        <RadioGroup
                            row
                            name="row-radio-buttons-group"
                            value={selectedOwnerSearch}
                            onChange={handleOwnerSearchChange}
                        >
                            <StyledRadioButton minWidth="270px" value={assessmentOwnerSearch}
                                sx={{ paddingRight: "15px" }}
                                control={<Radio />} label="Assessment Owner Name Search" />
                            <StyledRadioButton sx={{ fontWeight: 600 }} value={policyOwnerSearch}
                                control={<Radio />} label="Policy Owner Name Search" />
                        </RadioGroup>
                    </FormControl>
                    <Box sx={{ marginTop: '15px', marginBottom: '10px' }}>
                        <ValidationText>*State, County and Owner Name Required</ValidationText>
                    </Box>
                </Box>
                {
                    selectedOwnerSearch === assessmentOwnerSearch
                    &&
                    <>
                        <Box className={styles.boxLayout}>

                            <AssessmentOwnerSearchForm
                                raiseError={handleFormError}
                                onSubmit={handleSubmit}
                                onClear={handleClearSearch}
                                formFields={assessmentOwnerSearchState?.formFields}
                            />
                            {
                                errorMessage
                                &&
                                <ErrorMessage errorMessageText={errorMessage} />
                            }
                        </Box>

                    </>
                }
                {
                    selectedOwnerSearch === policyOwnerSearch
                    &&
                    <>
                        <PolicyOwnerNameSearch />
                    </>
                }
                {
                    selectedOwnerSearch === assessmentOwnerSearch
                    && matchCode
                    && assessmentOwnerSearchState
                    && assessmentOwnerSearchState.property
                    && assessmentOwnerSearchState.property.AssessmentOwnerSearch
                    && Object.keys(assessmentOwnerSearchState.property.AssessmentOwnerSearch).length > 0
                    &&
                    < MatchStatus matchCode={matchCode} matchText={matchText} resultText={resultText} />
                }
                {
                    selectedOwnerSearch === assessmentOwnerSearch
                    && assessmentOwnerSearchState
                    && assessmentOwnerSearchState.property
                    && assessmentOwnerSearchState.property.AssessmentOwnerSearch
                    && !assessmentOwnerSearchState.property.isExecuting
                    && Object.keys(assessmentOwnerSearchState.property.AssessmentOwnerSearch).length > 0
                    && (Object.keys(assessmentOwnerSearchState.property.AssessmentOwnerSearch).length > 0 && assessmentOwnerSearchState.property.AssessmentOwnerSearch[1].length > 0)
                    &&
                    <div>
                        <Box sx={{ mt: '15px', width: '100%' }}>
                            <SpectrTablePagination
                                tabName={OWNER_SEARCH_TAB}
                                navigationTabName={OWNER_SEARCH_TAB}
                                columns={columns}
                                rows={[]}
                                propertyList={assessmentOwnerSearchState.property.AssessmentOwnerSearch}
                                handleChangePage1={handleChangePage}
                                handleChangeRowsPerPage1={handleChangeRowsPerPage}
                                fetchNextData={handleFetchNext}
                                totalRowCount={assessmentOwnerSearchState.property.total_count ?? 0} />
                        </Box>
                    </div>
                }
            </TabPanel >

        </>
    )
}

export default OwnerNameSearch